import {
  benefitsClassSchema,
  EEmploymentStatus,
  EPaySchedule,
  Jurisdiction,
  schemes,
  ZealErrorAlias,
  zSchema,
} from "@zeal/common";

export const employeeCreateSchema = zSchema
  .object({
    address: zSchema.string().optional(),
    address_line2: zSchema.string().optional(),
    benefits_class: benefitsClassSchema.optional(),
    city: zSchema.string().optional(),
    default_pay_schedule: zSchema.nativeEnum(EPaySchedule),
    default_wage: zSchema.number().min(0),
    dob: schemes.isoDate().optional(),
    doubletime_rate: zSchema.number().min(0).optional(),
    email: schemes.email(),
    external_id: zSchema.string().optional(),
    first_name: zSchema.string(),
    is_943: zSchema.boolean().optional(),
    is_scheduleH: zSchema.boolean().optional(),
    last_name: zSchema.string(),
    metadata: schemes.metadata().optional(),
    overtime_rate: zSchema.number().min(0).optional(),
    phone_number: zSchema.string().optional(),
    ssn: zSchema
      .string()
      .regex(/^\d{9}$/, { message: ZealErrorAlias.INVALID_SSN.message })
      .optional(),
    start_date: zSchema.string().optional(),
    state: zSchema.nativeEnum(Jurisdiction).optional(),
    title: zSchema.string(),
    work_week_start: zSchema.string().optional(),
    workLocationID: schemes.mongoObjectId(),
    zip: zSchema.string().optional(),
    salary: zSchema.number().min(0).optional(),
    is_salary: zSchema.boolean().optional(),
    is_regular: zSchema.boolean().optional(),
  })
  .refine(
    ({ is_salary, salary }) => {
      if (is_salary === true) {
        return salary !== undefined;
      }
      return true;
    },
    {
      message: "salary is required when is_salary is true",
      path: ["salary"],
    }
  );

export type TEmployeeCreateSchema = zSchema.infer<typeof employeeCreateSchema>;

export const postEmployeesRequestSchema = zSchema.object({
  companyID: schemes.companyID(),
  new_employees: zSchema.array(employeeCreateSchema),
});

export type TPostEmployeesRequestSchema = zSchema.infer<
  typeof postEmployeesRequestSchema
>;

export const postEmployeesResponseSchema = zSchema.array(
  zSchema.object({
    address: zSchema.string(),
    address_line2: zSchema.string().nullable(),
    employeeID: schemes.mongoObjectId(),
    city: zSchema.string(),
    companyID: schemes.companyID(),
    default_dt_wage: zSchema.number().nullable(),
    default_pay_schedule: zSchema.nativeEnum(EPaySchedule),
    default_ot_wage: zSchema.number().nullable(),
    default_wage: zSchema.number().nullable(),
    dob: schemes.isoDate().nullable(),
    email: schemes.email(),
    employment_status: zSchema.nativeEnum(EEmploymentStatus),
    first_name: zSchema.string(),
    is_943: zSchema.boolean(),
    is_scheduleH: zSchema.boolean(),
    last_name: zSchema.string(),
    metadata: schemes.metadata(),
    middle_initial: zSchema.string().nullable(),
    onboarded: zSchema.boolean(),
    phone_number: zSchema.string().nullable(),
    start_date: schemes.isoDate().nullable(),
    state: zSchema.string(),
    term_date: schemes.isoDate().nullable(),
    title: zSchema.string(),
    work_week_start: zSchema.string().datetime().nullable(),
    workLocationID: schemes.mongoObjectId(),
    zip: zSchema.string(),
    salary: zSchema.number().nullable(),
    is_salary: zSchema.boolean().nullable(),
    is_regular: zSchema.boolean().nullable(),
  })
);

export type TPostEmployeesResponseSchema = zSchema.infer<
  typeof postEmployeesResponseSchema
>;
