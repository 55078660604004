export const EWorfRoute = {
  CASE_FIELDS: "/api/case-fields",
  CLOSE_CASE: "/api/close-case",
  CONFIRM_DETAILS: "/api/:case_number/confirm-details",
  CONFIRM_DETAILS_FIELDS: "/api/:case_number/confirm-details-fields",
  CREATE_CASE: "/api/create-case",
  DUPLICATE_CHECK: "/api/duplicate-check",
  FURTHER_ACTION_NOTICE: "/api/:case_number/further-action-notice",
  LOGIN: "/api/login",
  REFER_CASE: "/api/:case_number/refer-case",
  REFERRAL_DATE_CONFIRMATION: "/api/:case_number/referral-date-confirmation",
  SUBMIT_CASE: "/api/submit-case/:case_number",
  TOKEN_REFRESH: "/api/token-refresh",
  TOKEN_VALIDATE: "/api/token-validate",
  UPLOAD_CASE_DOCUMENT: "/api/upload-case-document",

  /**
   * Following routes are triggered by la-forge
   */
  RESOLVE_FINAL_NON_CONFIRMATION: "/api/resolve-final-non-confirmation",
  RESOLVE_PENDING_REFERRAL: "/api/resolve-pending-referral",
  TRIGGER_CASE_ON_ONBOARDING: "/api/trigger-case-on-onboarding",
} as const;

export type EWorfRoute = (typeof EWorfRoute)[keyof typeof EWorfRoute];
