import { EVeriffDocumentType } from "@zeal/common";

import { z } from "zod";

export const createVeriffSessionRequestBodySchema = z.object({
  verification: z.object({
    person: z.object({
      firstName: z.string(),
      lastName: z.string(),
      idNumber: z.string().optional(), // National identification number
      gender: z.string().optional(),
      dateOfBirth: z.string().optional(),
    }),
    document: z.object({
      number: z.string().optional(), // Document number, [a-zA-Z0-9] characters only
      type: z.nativeEnum(EVeriffDocumentType),
      country: z.string(), // ISO-2 String Issuing country of the document
    }),
    lang: z.string(),
    timestamp: z.string(),
  }),
});

export type TCreateVeriffSessionRequestBody = z.infer<
  typeof createVeriffSessionRequestBodySchema
>;

export interface ICreateVeriffSessionResponse {
  status: "success";
  verification: {
    id: string; // String UUID v4 which identifies the verification session
    url: string; // URL of the verification to which the person is redirected
    vendorData: string | null; // End-user-specific data string, max 1000 characters long, created by the customer.
    host: string; // The base url the sessionToken can be used for
    status: string; // Verification session status
    sessionToken: string; // Session-specific token of the verification
  };
}
