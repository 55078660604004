import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  ICreateVeriffSessionResponse,
  TCreateVeriffSessionRequestBody,
} from "./createVeriffSessionSchema";

export const createVeriffSession = (axios: IAxiosInstance) => {
  return async (requestBody: TCreateVeriffSessionRequestBody) => {
    const response = await axios.noRetry.post<
      ZealApiResponse<ICreateVeriffSessionResponse>
    >(`/api/createVeriffSession`, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
