import { TCustomerAccountTotals } from "../employee-checks";

export const EEmployerCheckStatus = {
  Pending: "pending",
  PreProcessed: "pre-processed",
  Processed: "processed",
} as const;

export type EEmployerCheckStatus =
  (typeof EEmployerCheckStatus)[keyof typeof EEmployerCheckStatus];

export interface IEmployerCheckTotals {
  company_cash_requirement: number;
  company_debit: number;
  employee_deductions: number;
  employer_deductions: number;
  employee_benefit_deductions: number;
  employer_benefit_deductions: number;
  gross_pay: number;
  net_pay: number;
  total_employee_taxes: number;
  total_employer_taxes: number;
  void_company_cash_requirement?: number;
  void_company_debit?: number;
  void_gross_pay?: number;
  void_net_pay?: number;
  void_total_employee_taxes?: number;
  void_total_employer_taxes?: number;
}

export interface IEmployerCheck {
  readonly companyID: string;
  readonly customer_account_totals: TCustomerAccountTotals;
  readonly id: string;
  readonly processed: boolean;
  status: EEmployerCheckStatus;
  readonly reporting_period_end: Date;
  readonly reporting_period_start: Date;
  readonly reporting_periods: string[];
  readonly totals: IEmployerCheckTotals;
}
