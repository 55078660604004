import { EEVerifyPublicCaseStatus, schemes } from "@zeal/common";
import { z } from "zod";

export const updateEVerifyPublicCaseStatusRequestZodSchema = z.object({
  workerID: schemes.mongoObjectId(),
  everify_status: z.nativeEnum(EEVerifyPublicCaseStatus),
  everify_status_display: z.string().nullish(),
  everify_status_updated_at: z.string(),
  everify_ee_continues_to_work_after_fnc: z.boolean().optional(),
  everify_referral_date_confirmation_url: z.string().optional(),
  testMode: z.boolean(),
});

export type TUpdateEVerifyPublicCaseStatus = z.infer<
  typeof updateEVerifyPublicCaseStatusRequestZodSchema
>;
